"use strict";

/**
 * function to push data to the dataLayer, and add hash
 * @param {Object} data - data to push
 */
function dataPush(data) {
    if (data) {
        if (data.eventType) {
            delete data.eventType;
        }

        // Clear the previous ecommerce object.
        if ("ecommerce" in data && !!data.ecommerce) {
            dataLayer.push({ // eslint-disable-line
                ecommerce: null
            });
        }

        // Handle navigation after the ecommerce data has been sent to Google Analytics.
        if (data.eventRedirect) {
            var redirectUrl = data.eventRedirect;
            data.eventCallback = function () {
                document.location = redirectUrl;
            };
            delete data.eventRedirect;
        }

        if ((location.hash.indexOf(data.event + "gtm") == -1)) {
            dataLayer.push(data); // eslint-disable-line
        }
    }
}

/**
 * Parse the data object into json
 * @param {String} data
 * @returns {Object} jsonObject
 */
function parseJSON(data) {
    var parsedJson = {};
    try {
        parsedJson = JSON.parse(data);
    } catch (error) {
        parsedJson = null;
    }
    return parsedJson;
}

/**
 * find, parse and add to dataLayer data-gtm from all tags in node
 * @param {Object} node - DOM node, inside which will be search
 */
function findAllDataDOM(node) {
    var allGTMData = $(node).find("[data-gtm]");
    var itemData = {};
    var dataCollectionObj = {};

    if (!allGTMData.length) {
        return false;
    }

    $.each(allGTMData, function (i) {
        var tempNode = allGTMData[i];
        dataCollectionObj = parseJSON($(tempNode).attr("data-gtm"));

        if (!dataCollectionObj || !dataCollectionObj.eventType || !dataCollectionObj.ecommerce) {
            return;
        }

        if (dataCollectionObj.eventType.indexOf("show") != -1) {
            if (Object.keys(itemData).length === 0) {
                // Prevent adding the same promoView event in case of loop slider.
                if (!$(tempNode).parent().hasClass("swiper-slide-duplicate")) {
                    Object.assign(itemData, dataCollectionObj);
                }
            } else {
                if ("impressions" in itemData.ecommerce) {
                    itemData.ecommerce.impressions.push(dataCollectionObj.ecommerce.impressions[0]);
                } else if ("promoView" in itemData.ecommerce) {
                    // Prevent adding the same promoView event in case of loop slider.
                    if (!$(tempNode).parent().hasClass("swiper-slide-duplicate")) {
                        itemData.ecommerce.promoView.promotions.push(dataCollectionObj.ecommerce.promoView.promotions[0]);
                    }
                }
            }
            $(tempNode).removeAttr("data-gtm");
        }
    });

    if (Object.keys(itemData).length !== 0) {
        dataPush(itemData);
    }
}

/**
 * run AJAX/Fetch listener, to find all (gtm_data in JSON) or (gtm-data in HTML).
 */
function GTMListenerRun() {
    // Fetch Listener
    const fetch = window.fetch;
    window.fetch = (...args) => (async (args) => {
        var result = await fetch(...args);
        var res = result.clone();
        const contentType = res.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") > -1) {
            var resJson = res.json();
            resJson.then(data => {
                if (data.gtm_data) {
                    dataPush(data.gtm_data);
                }
            });
        }
        return result;
    })(args);

    // AJAX Listener
    var listenerSend = XMLHttpRequest.prototype.send;
    XMLHttpRequest.prototype.send = function () {
        var callback = this.onreadystatechange;
        this.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                if (this.getAllResponseHeaders().indexOf("application/json") != -1) {
                    var myArr = parseJSON(this.response);
                    if (myArr.gtm_data) {
                        var parsedGTM = parseJSON(myArr.gtm_data);
                        dataPush(parsedGTM);
                    }
                }
            }
            if (callback) {
                callback.apply(this, arguments);
            }
        };
        listenerSend.apply(this, arguments);
    };
}

//run DOM updates listener, to find all gtm-data added to DOM
function DOMListenerRun() {
    var observer = new MutationObserver(function (mutationsList, observer) { // eslint-disable-line
        findAllDataDOM(document);
    });
    var targetNode = document.body,
        config     = {childList: true, subtree: true};
    observer.observe(targetNode, config);
}

function clickListenerRun() {
    $(document).on("click", "[data-gtm]", function (e) { // eslint-disable-line
        var gtmElement = parseJSON(e.currentTarget.getAttribute("data-gtm"));

        // prevent default redirect to properly sent dataLayer and then do redirect.
        if (gtmElement && gtmElement.eventRedirect) {
            e.preventDefault();
        }

        if (gtmElement && gtmElement.eventType && gtmElement.eventType.indexOf("click") !== -1) {
            dataPush(gtmElement);
        }
    });
}

document.addEventListener("DOMContentLoaded", function () {
    DOMListenerRun();
    findAllDataDOM(document);
    GTMListenerRun();
    clickListenerRun();
});
